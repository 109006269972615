import React from 'react';

function HomePage() {
    return (
        <div className="home">
            <h1>Home Tika test hello wih the entire world</h1>
        </div>
    );
}

export default HomePage;