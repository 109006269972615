import React from 'react';

function DocumentsHomePage() {
    return (
        <div className="documents">
            <h1>Documents</h1>
            <div>gdflgjdfgkdjfgkdfgkhdfkghkjdfghjkdfghh</div>
        </div>
    );
}

export default DocumentsHomePage;
