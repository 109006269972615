import React from 'react';

function OrganizationPage() {
    return (
        <div className="home">
            <h1>Organization</h1>
        </div>
    );
}

export default OrganizationPage;
