import React from 'react';

function AppointmentPage() {
    console.log('Loading appointment page');
    return (
        <div className="appointment">
            <h1>Appointment</h1>
            <div>gdflgjdfgkdjfgkdfgkhdfkghkjdfghjkdfghh</div>
        </div>
    );
}

export default AppointmentPage;
